<template>
  <div id="help">
    <div class="header">
      <div class="header-1">
        常见问题中心
      </div>
      <input
        @click="inputClick()"
        @blur="blur()"
        type="text"
        v-model="lianxiangVal"
        placeholder="请输入关键词搜索，例如“英语”"
      />
      <img :src="fontAssetsURL(`help/搜索icon.png`)" alt="" v-if="!this.showme" />
      <img :src="fontAssetsURL(`help/搜索icon@2x.png`)" alt="" v-if="this.showme" />

      <!--联想框  -->
      <div class="lxfu">
        <help-lianxiang ref="help"></help-lianxiang>
      </div>
    </div>
    <div class="line2">
      <div class="line2-1">
        <div class="line2-1-item" :class="{ active: isHot }" @click="clickLine3Lefthot('hot')">
          <div class="left-hot"><span>热门问题</span></div>
          <!-- <div class="right">></div> -->
        </div>
        <div
          class="line2-1-item"
          v-for="(item, index) in line2List"
          :key="item.id"
          :class="{ active: !isHot }"
          @click="clickLine3Left(item, index)"
        >
          <div class="left">
            <span>{{ item.name }}</span>
          </div>
          <div class="right">
            <img
              class="right-img"
              ref="jiantou"
              :src="fontAssetsURL(`help/箭头 拷贝.png`)"
              alt=""
              v-if="!showme"
            />
            <img
              class="right-img"
              ref="jiantou"
              :src="fontAssetsURL(`help/箭头 拷贝@2x.png`)"
              alt=""
              v-if="showme"
            />
          </div>

          <transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:after-enter="afterEnter"
            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
            v-on:after-leave="afterLeave"
          >
            <div class="stu" v-if="isSelf">
              <div
                class="stu-item"
                v-for="(item1, index1) in item.children"
                :key="item1.id"
                :class="{ stuItemAct: currentitem === index1 }"
                @click.stop="leftClick(item1, index1)"
              >
                <div class="left">
                  <span>{{ item1.name }}</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="line2-2">
        <!-- 右侧组件 -->
        <comp ref="comp"></comp>
      </div>
    </div>
  </div>
</template>

<script>
import comp from "./helpComp/helpComp.vue";
import helpLianxiang from "./helpComp/helpLianxiang.vue";

import { menus, menus2, problems } from "../assets/questionList.js";
import { getHelp } from "../server/api";

function hasClass(el, clsName) {
  if (el.classList.contains(clsName)) return true;
}

function trim(x) {
  return x.replace(/^\s+|\s+$/gm, "");
}

function addClass(el, cls) {
  if (!el) return;
  var curClass = el.className;
  var classes = (cls || "").split(" ");

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else if (!hasClass(el, clsName)) {
      curClass += " " + clsName;
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
}

function removeClass(el, cls) {
  if (!el || !cls) return;
  var classes = cls.split(" ");
  var curClass = " " + el.className + " ";

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.remove(clsName);
    } else if (hasClass(el, clsName)) {
      curClass = curClass.replace(" " + clsName + " ", " ");
    }
  }
  if (!el.classList) {
    el.className = trim(curClass);
  }
}

export default {
  data() {
    return {
      showme: 0, //图片切换
      line2List: [], //line2左侧数据
      currentitem: 3, //当前line2左侧选择项
      isSelf: 0,
      currentcomp: "comp1",
      pValue: "", //传递值
      isHot: true, //热门问题
      menus,
      menus2,
      problems,
      lianxiangVal: "", //联想值

      timer: null,
    };
  },
  components: {
    comp,
    helpLianxiang,
  },
  created() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  // 监听输入框值，改动=》触发联想
  watch: {
    lianxiangVal: {
      handler(value) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.$refs.help.getData(this.lianxiangVal);
        }, 1000);
      },
      deep: true,
    },
  },

  methods: {
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      this.showme = currentWidth > 1920 ? 0 : 0;
      //初始左侧学生帮助
      this.line2List = [menus2[2]];
      //初始给右侧传值
      localStorage.removeItem("hotProblem");
      this.$refs.comp.getData("hot");
    },

    // 点击输入框  激活态
    inputClick() {
      this.$refs.help.getData(this.lianxiangVal);
    },
    //  失去焦点 关闭联想提示框
    blur() {
      setTimeout(() => {
        this.$refs.help.showlianxiang = false;
      }, 500);
    },

    //line3点击      hot
    clickLine3Lefthot(val) {
      this.isHot = true;
      this.isSelf = false;

      // 关闭学生帮助的颜色显示
      this.currentitem = 3;

      this.$refs.comp.closeanswer();
      // 这里给右侧组件传值
      this.$refs.comp.getData(val);
    },

    //line3点击左侧   非hot
    leftClick(val, index) {
      this.currentitem = index;
      this.isHot = false;
      this.$refs.comp.closeanswer();
      // 这里给右侧组件传值
      this.$refs.comp.getData(val);
    },

    //line3点击左侧  学生帮助
    clickLine3Left(val, param) {
      // if (this.currentitem != 3) {
      //   this.isHot = false
      // }
      this.isSelf = !this.isSelf;
      if (this.isSelf) {
        document.querySelector(".right-img").style.transform = "rotateZ(90deg)";
      } else {
        document.querySelector(".right-img").style.transform = "rotateZ(0deg)";
      }

      this.$refs.comp.closeanswer();
    },

    beforeEnter(el) {
      addClass(el, "collapse-transition");
      el.style.height = "0";
    },
    enter(el) {
      if (el.scrollHeight !== 0) {
        el.style.height = el.scrollHeight + "px";
      } else {
        el.style.height = "";
      }

      el.style.overflow = "hidden";
    },

    afterEnter(el) {
      removeClass(el, "collapse-transition");
      el.style.height = "";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
      el.style.overflow = "hidden";
    },
    leave(el) {
      if (el.scrollHeight !== 0) {
        addClass(el, "collapse-transition");
        el.style.height = 0;
      }
    },
    afterLeave(el) {
      removeClass(el, "collapse-transition");
      el.style.height = "";
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/help.styl'
</style>
